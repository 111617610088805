"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var bisect_1 = require("../utils/bisect");
var json_resources_1 = require("../core/json-resources");
var routes_1 = require("../routes");
var resource_api_1 = require("../schemas/resource-api");
var newResource = resource_api_1.ResourceApi.newResource;
var toast_reducer_1 = require("./toast-reducer");
var I18n_1 = require("../I18n");
var isErrorResponse = resource_api_1.ResourceApi.isErrorResponse;
var stores_1 = require("../stores");
var removeResource = stores_1.Stores.removeResource;
var csv_service_1 = require("../core/services/csv-service");
var sequence_services_1 = require("../core/services/sequence-services");
var input_debouncing_services_1 = require("../core/services/input-debouncing-services");
var upload_normal_services_1 = require("../core/services/upload-normal-services");
function uploadTemplateReport(event) {
    return {
        type: "upload-template-report",
        event: event,
    };
}
exports.uploadTemplateReport = uploadTemplateReport;
function templateResponseModalOpen() {
    return {
        type: "template-response-modal-open",
    };
}
exports.templateResponseModalOpen = templateResponseModalOpen;
function templateResponseModalSubmit(templateResponseId) {
    return {
        type: "template-response-modal-submit",
        templateResponseId: templateResponseId
    };
}
exports.templateResponseModalSubmit = templateResponseModalSubmit;
function templateResponseModalClose() {
    return {
        type: "template-response-modal-close"
    };
}
exports.templateResponseModalClose = templateResponseModalClose;
function addTag(text) {
    return {
        type: "add-tag",
        text: text
    };
}
exports.addTag = addTag;
function deleteTag(text) {
    return {
        type: "delete-tag",
        text: text
    };
}
exports.deleteTag = deleteTag;
function saveTemplateResponse() {
    return {
        type: "save-template-response"
    };
}
exports.saveTemplateResponse = saveTemplateResponse;
function editTemplateResponse(id) {
    return {
        type: "edit-template-response",
        id: id
    };
}
exports.editTemplateResponse = editTemplateResponse;
function alertDelete(id) {
    return {
        type: "alert-delete",
        id: id
    };
}
exports.alertDelete = alertDelete;
function deleteTemplateResponse(id) {
    return {
        type: "delete-template-response",
        id: id
    };
}
exports.deleteTemplateResponse = deleteTemplateResponse;
function createTemplateResponse() {
    return {
        type: "create-template-response",
    };
}
exports.createTemplateResponse = createTemplateResponse;
function cancelTemplateResponse() {
    return {
        type: "cancel-template-response",
    };
}
exports.cancelTemplateResponse = cancelTemplateResponse;
function closePopupTemplateResponse() {
    return {
        type: "close-popup-template-response"
    };
}
exports.closePopupTemplateResponse = closePopupTemplateResponse;
function cancelClosePopupTemplateResponse() {
    return {
        type: "cancel-close-popup-template-response"
    };
}
exports.cancelClosePopupTemplateResponse = cancelClosePopupTemplateResponse;
function openPopupTemplateResponse(id) {
    return {
        type: "open-popup-template-response",
        id: id,
    };
}
exports.openPopupTemplateResponse = openPopupTemplateResponse;
function openPopupYoutubeTemplateResponse(id) {
    return {
        type: "open-popup-youtube-template-response",
        id: id,
    };
}
exports.openPopupYoutubeTemplateResponse = openPopupYoutubeTemplateResponse;
function openPopupCreateTemplateResponse(id) {
    return {
        type: "open-popup-create-template-response",
        id: id,
    };
}
exports.openPopupCreateTemplateResponse = openPopupCreateTemplateResponse;
function openPopupYoutubeCreateTemplateResponse(id) {
    return {
        type: "open-popup-youtube-create-template-response",
        id: id,
    };
}
exports.openPopupYoutubeCreateTemplateResponse = openPopupYoutubeCreateTemplateResponse;
function uploadTemplateResponseFile(event) {
    return {
        type: "upload-template-response-video-tag-file",
        event: event,
    };
}
exports.uploadTemplateResponseFile = uploadTemplateResponseFile;
function uploadTemplateResponseVideoTagThumbnailFile(event, idx) {
    return {
        type: "upload-template-response-video-tag-thumbnail-file",
        event: event,
        idx: idx,
    };
}
exports.uploadTemplateResponseVideoTagThumbnailFile = uploadTemplateResponseVideoTagThumbnailFile;
function deleteTemplateResponseVideoTagThumbnailFile(video_tag_id, idx) {
    return {
        type: "delete-template-response-video-tag-thumbnail-file",
        video_tag_id: video_tag_id,
        idx: idx,
    };
}
exports.deleteTemplateResponseVideoTagThumbnailFile = deleteTemplateResponseVideoTagThumbnailFile;
function checkTemplateResponseValid(state) {
    return state.inputs.templateResponseQuestion != "" &&
        state.inputs.templateResponseAnswer != "";
}
exports.checkTemplateResponseValid = checkTemplateResponseValid;
function deleteVideoTagTemplateResponse() {
    return {
        type: "delete-video-tag-template-response"
    };
}
exports.deleteVideoTagTemplateResponse = deleteVideoTagTemplateResponse;
function deleteYoutubeTemplateResponse() {
    return {
        type: "delete-youtube-template-response"
    };
}
exports.deleteYoutubeTemplateResponse = deleteYoutubeTemplateResponse;
function changeYoutubeIdTemplateResponse(value) {
    return {
        type: "change-youtube-id-template-response",
        value: value,
    };
}
exports.changeYoutubeIdTemplateResponse = changeYoutubeIdTemplateResponse;
exports.uploadRequestName = ["template-report"];
function reduceTemplateResponses(state, action) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var effects = [];
    var language = state.loggedInUser.attributes.language;
    switch (action.type) {
        case "complete-request":
            if (action.name[0] === exports.templateResponseLoadRequestName) {
                var responseDetails = json_resources_1.getResourceResponseDetails(action);
                var apiResponse = responseDetails.apiResponse;
                if (!apiResponse || isErrorResponse(apiResponse))
                    break;
                state = tslib_1.__assign({}, state);
                state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
                state.templateResponsesPage.templateResponsesCount = apiResponse.meta ? apiResponse.meta.record_count : 0;
            }
            else if (action.name[0] === exports.saveTemplateResponseRequestName) {
                var responseDetails = json_resources_1.getResourceResponseDetails(action);
                if (!responseDetails.resources)
                    break;
                if (responseDetails.resources.length === 0)
                    break;
                var apiResponse = responseDetails.apiResponse;
                if (!apiResponse || isErrorResponse(apiResponse))
                    break;
                state = tslib_1.__assign({}, state);
                if (state.toggles.newTemplateResponse) {
                    state.inputs = tslib_1.__assign({}, state.inputs, { templateResponseSearch: "" });
                    state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
                    state.templateResponsesPage.templateResponsesCount = apiResponse.meta ? apiResponse.meta.record_count : 0;
                }
                responseDetails.resources.map(function (resource) {
                    if (resource.type !== "template_responses")
                        return;
                    state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
                    state.templateResponsesPage.tags = [];
                    state.inputs = tslib_1.__assign({}, state.inputs, { templateResponseId: "", templateResponseQuestion: "", templateResponseAnswer: "", templateResponseTags: "", templateResponseVideoTagData: [], templateResponseYoutubeId: "", templateResponseThumbnailLoadingIdx: [], templateResponseDeleteVideoTagId: "" });
                    state.toggles = tslib_1.__assign({}, state.toggles, { newTemplateResponse: false });
                });
            }
            else if (action.name[0] === exports.deleteTemplateResponseRequestName) {
                if (!action.success)
                    break;
                var resourceId = action.name[1];
                state.stores = tslib_1.__assign({}, state.stores);
                state.stores = removeResource({ type: "template_responses", id: resourceId }, state.stores);
                state = tslib_1.__assign({}, state);
                state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
                state.templateResponsesPage.templateResponsesCount--;
            }
            else if (action.name[0] === exports.deleteVideoTagThumbnailFileTemplateResponseRequestName) {
                state = tslib_1.__assign({}, state);
                state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
                state.inputs = tslib_1.__assign({}, state.inputs);
                var idx_1 = Number(action.name[1]);
                state.templateResponsesPage.viewVideoTagData[idx_1].thumbnail_url = "";
                if (action.success) {
                    var data = JSON.parse(action.response);
                    if (data.type !== "show_contents")
                        return;
                    if (data.thumbnail_url && data.thumbnail_url !== null && data.thumbnail_url !== "") {
                        state.templateResponsesPage.viewVideoTagData[idx_1].thumbnail_url = data.thumbnail_url;
                    }
                }
                state.inputs.templateResponseThumbnailLoadingIdx = state.inputs.templateResponseThumbnailLoadingIdx.filter(function (value) { return value !== idx_1; });
                (_a = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.templateResponseModalUpdateDefaultVideoTagThumbnailMessage[language])), state = _a.state, effects = _a.effects);
            }
            break;
        case "upload-normal-success":
            if (action.name[0] === exports.uploadTemplateResponseFileRequestName) {
                console.log(action);
                state = tslib_1.__assign({}, state);
                state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
                state.inputs = tslib_1.__assign({}, state.inputs);
                if (state.templateResponsesPage.uploadFileErrorFlg) {
                    state.templateResponsesPage.viewVideoTagData = [];
                }
                else {
                    if (action.uploadedResponse) {
                        var data = action.uploadedResponse;
                        var templateResponseVideoTagData = {};
                        templateResponseVideoTagData.type = data.type;
                        templateResponseVideoTagData.format = data.format;
                        templateResponseVideoTagData.original_filename = data.original_filename;
                        templateResponseVideoTagData.size = data.size;
                        templateResponseVideoTagData.src_url = data.src_url;
                        templateResponseVideoTagData.url = data.src_url;
                        templateResponseVideoTagData.kind = "video_tag";
                        templateResponseVideoTagData.thumbnail_url = data.thumbnail_url;
                        templateResponseVideoTagData.video_tag_id = data.video_tag_id;
                        if (templateResponseVideoTagData.src_url && templateResponseVideoTagData.src_url.startsWith("/")) {
                            var currentDomain = window.location.hostname;
                            var currentPort = window.location.port;
                            var currentProtocol = window.location.protocol;
                            if (currentPort && (currentPort == "443" || currentPort == "80")) {
                                currentPort = "";
                            }
                            templateResponseVideoTagData.src_url = currentProtocol + "//" + currentDomain + (currentPort ? ":" + currentPort : "") + templateResponseVideoTagData.src_url;
                        }
                        state.templateResponsesPage.viewVideoTagData.push(templateResponseVideoTagData);
                        if (action.uploadEnd) {
                            (_b = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.uploadSuccessMessage[language])), state = _b.state, effects = _b.effects);
                        }
                    }
                }
                if (action.uploadEnd) {
                    state.toggles.loadingFlg = false;
                }
            }
            if (action.name[0] === exports.uploadTemplateResponseThumbnailFileRequestName) {
                state = tslib_1.__assign({}, state);
                state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
                state.inputs = tslib_1.__assign({}, state.inputs);
                var idx_2 = Number(action.name[1]);
                if (action.uploadedResponse) {
                    var data = action.uploadedResponse;
                    if (data.type !== "show_contents")
                        return;
                    if (data.thumbnail_url && data.thumbnail_url !== null && data.thumbnail_url !== "") {
                        state.templateResponsesPage.viewVideoTagData[idx_2].thumbnail_url = data.thumbnail_url;
                        (_c = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.uploadSuccessMessage[language])), state = _c.state, effects = _c.effects);
                    }
                    else {
                        (_d = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.uploadFailureMessage[language])), state = _d.state, effects = _d.effects);
                    }
                }
                else {
                    (_e = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.uploadFailureMessage[language])), state = _e.state, effects = _e.effects);
                }
                state.inputs.templateResponseThumbnailLoadingIdx = state.inputs.templateResponseThumbnailLoadingIdx.filter(function (value) { return value !== idx_2; });
            }
            break;
        case "upload-normal-failure":
            if (action.name[0] === exports.uploadTemplateResponseFileRequestName) {
                state = tslib_1.__assign({}, state);
                state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
                state.inputs = tslib_1.__assign({}, state.inputs);
                if (!state.templateResponsesPage.uploadFileErrorFlg) {
                    (_f = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.uploadFailureMessage[language])), state = _f.state, effects = _f.effects);
                }
                state.templateResponsesPage.uploadFileErrorFlg = true;
                state.templateResponsesPage.viewVideoTagData = [];
                if (action.uploadEnd) {
                    state.toggles.loadingFlg = false;
                }
            }
            if (action.name[0] === exports.uploadTemplateResponseThumbnailFileRequestName) {
                state = tslib_1.__assign({}, state);
                state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
                var idx_3 = Number(action.name[1]);
                state.templateResponsesPage.uploadFileErrorFlg = true;
                (_g = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.uploadFailureMessage[language])), state = _g.state, effects = _g.effects);
                state.inputs.templateResponseThumbnailLoadingIdx = state.inputs.templateResponseThumbnailLoadingIdx.filter(function (value) { return value !== idx_3; });
            }
            break;
        case "template-response-modal-open":
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceFetch([exports.templateResponseLoadRequestName], routes_1.RapiV1TemplateResponsesPath));
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.templateResponsesModalToggle = true;
            break;
        case "template-response-modal-submit":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.templateResponsesModalToggle = false;
            var templateResponse = bisect_1.Indexer.getFirstMatching(state.stores.templateResponses.byId, [action.templateResponseId]);
            if (templateResponse) {
                state.roomInputs = tslib_1.__assign({}, state.roomInputs);
                state.roomInputs[state.currentViewingRoomId] = templateResponse.attributes.message_text;
                state.roomTemplateResponses = tslib_1.__assign({}, state.roomTemplateResponses);
                state.roomTemplateResponses[state.currentViewingRoomId] = templateResponse.attributes.message_text;
            }
            break;
        case "template-response-modal-close":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.toggles.templateResponsesModalToggle = false;
            state.inputs.templateResponsesModalSearch = "";
            break;
        case "add-tag":
            var text = action.text.trim();
            if (!text)
                break;
            state = tslib_1.__assign({}, state);
            state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
            if (state.templateResponsesPage.tags.indexOf(text) === -1) {
                state.templateResponsesPage.tags = state.templateResponsesPage.tags.slice();
                state.templateResponsesPage.tags = state.templateResponsesPage.tags.concat(text);
            }
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.inputs.templateResponseTags = "";
            effects = sequence_services_1.sequence(effects, input_debouncing_services_1.clearInputDebouncing());
            break;
        case "delete-tag":
            var text = action.text.trim();
            var index = state.templateResponsesPage.tags.indexOf(text);
            state = tslib_1.__assign({}, state);
            state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
            if (index !== -1) {
                state.templateResponsesPage.tags = state.templateResponsesPage.tags.slice();
                state.templateResponsesPage.tags.splice(index, 1);
            }
            break;
        case "save-template-response":
            var templateResponseData = newResource("template_responses");
            templateResponseData.attributes.tags = state.templateResponsesPage.tags;
            templateResponseData.attributes.message_text = state.inputs.templateResponseAnswer;
            templateResponseData.attributes.title = state.inputs.templateResponseQuestion;
            state.inputs = tslib_1.__assign({}, state.inputs);
            var templateResponseDataSettingsList = [];
            state.inputs.templateResponseVideoTagData.map(function (data) {
                var templateResponseDataSettings = {};
                templateResponseDataSettings.src_url = data.url;
                templateResponseDataSettings.type = data.type;
                templateResponseDataSettings.format = data.format;
                templateResponseDataSettings.original_filename = data.original_filename;
                templateResponseDataSettings.size = data.size;
                templateResponseDataSettings.kind = data.kind;
                templateResponseDataSettings.thumbnail_url = data.thumbnail_url;
                templateResponseDataSettings.video_tag_id = data.video_tag_id;
                templateResponseDataSettingsList.push(templateResponseDataSettings);
            });
            if (state.inputs.templateResponseYoutubeId && state.inputs.templateResponseYoutubeId !== null && state.inputs.templateResponseYoutubeId !== "") {
                var templateResponseDataSettings = {};
                templateResponseDataSettings.kind = "youtube";
                templateResponseDataSettings.youtube_id = state.inputs.templateResponseYoutubeId;
                templateResponseDataSettings.type = "";
                templateResponseDataSettingsList.push(templateResponseDataSettings);
            }
            templateResponseData.attributes.settings = templateResponseDataSettingsList;
            var id = templateResponseData.id = state.inputs.templateResponseId;
            var url = routes_1.RapiV1TemplateResponsesPath + (id ? "/" + id : "");
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceWrite([exports.saveTemplateResponseRequestName].concat((id ? [id] : [])), templateResponseData, url));
            (_h = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.feedbackToastFAQSaved[language])), state = _h.state, effects = _h.effects);
            break;
        case "create-template-response":
            state = tslib_1.__assign({}, state);
            state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
            state.templateResponsesPage.tags = [];
            state = createInitModal(state);
            state.inputs = tslib_1.__assign({}, state.inputs, { templateResponseId: "", templateResponseQuestion: "", templateResponseAnswer: "", templateResponseTags: "", templateResponseVideoTagData: [], templateResponseYoutubeId: "", templateResponseThumbnailLoadingIdx: [], templateResponseDeleteVideoTagId: "" });
            state.toggles = tslib_1.__assign({}, state.toggles, { newTemplateResponse: true });
            break;
        case "cancel-template-response":
            state = tslib_1.__assign({}, state);
            state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
            state.templateResponsesPage.tags = [];
            state = createInitModal(state);
            state.inputs = tslib_1.__assign({}, state.inputs, { templateResponseId: "", templateResponseQuestion: "", templateResponseAnswer: "", templateResponseTags: "", templateResponseVideoTagData: [], templateResponseYoutubeId: "", templateResponseThumbnailLoadingIdx: [], templateResponseDeleteVideoTagId: "" });
            state.toggles = tslib_1.__assign({}, state.toggles, { newTemplateResponse: false });
            break;
        case "edit-template-response":
            var tr = bisect_1.Indexer.getFirstMatching(state.stores.templateResponses.byId, [action.id]);
            state = tslib_1.__assign({}, state);
            state = createInitModal(state);
            if (tr) {
                state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
                state.templateResponsesPage.tags = tr.attributes.tags;
                var templateResponseVideoTagDataList = [];
                var youtubeId = "";
                if (tr.attributes.settings && tr.attributes.settings !== null && tr.attributes.settings.length > 0) {
                    tr.attributes.settings.map(function (data) {
                        if (data.kind && data.kind !== null && data.kind !== "") {
                            if (data.kind === "video_tag") {
                                var templateResponseVideoTagData = data;
                                templateResponseVideoTagData.url = data.src_url;
                                templateResponseVideoTagDataList.push(templateResponseVideoTagData);
                            }
                            else if (data.kind === "youtube" && data.youtube_id && data.youtube_id !== null && data.youtube_id !== "") {
                                youtubeId = data.youtube_id;
                            }
                        }
                    });
                }
                state.inputs = tslib_1.__assign({}, state.inputs, { templateResponseId: tr.id, templateResponseQuestion: tr.attributes.title, templateResponseAnswer: tr.attributes.message_text, templateResponseVideoTagData: templateResponseVideoTagDataList, templateResponseYoutubeId: youtubeId, templateResponseThumbnailLoadingIdx: [], templateResponseDeleteVideoTagId: "" });
            }
            state.toggles = tslib_1.__assign({}, state.toggles, { newTemplateResponse: false });
            break;
        case "alert-delete":
            state = tslib_1.__assign({}, state);
            state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.templateResponsesPage.templateResponseIdToBeDeleted = action.id;
            state.toggles.showTemplateResponseDeleteConfirmModal = true;
            break;
        case "delete-template-response":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.toggles.showTemplateResponseDeleteConfirmModal = false;
            effects = sequence_services_1.sequence(effects, json_resources_1.requestResourceDeleteByUrl([exports.deleteTemplateResponseRequestName, action.id], "/rapi/v1/template_responses/" + action.id));
            (_j = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.feedbackToastFAQDeleted[language])), state = _j.state, effects = _j.effects);
            break;
        case "toggle":
            if (action.target !== "templateResponsesPageDescendingOrder")
                break;
        case "input-change":
            if (action.target === "templateResponseSearch" || action.target === "templateResponsesPageOrderBy") {
                effects = sequence_services_1.sequence(effects, loadTemplateResponses(state.inputs.templateResponseSearch, state.inputs.templateResponsesPageOrderBy, state.toggles.templateResponsesPageDescendingOrder));
            }
            break;
        case "upload-template-report":
            var files = action.event.target.files;
            effects = sequence_services_1.sequence(effects, csv_service_1.uploadCSV(exports.uploadRequestName, '/rapi/v1/template_responses/import.csv', files));
            break;
        case "csv-upload-success":
            if (action.name[0] == exports.uploadRequestName[0]) {
                (_k = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.uploadSuccessMessage[language] + " " + action.filename)), state = _k.state, effects = _k.effects);
            }
            break;
        case "csv-upload-failure":
            if (action.name[0] == exports.uploadRequestName[0]) {
                (_l = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.uploadFailureMessage[language] + " " + action.filename)), state = _l.state, effects = _l.effects);
            }
            break;
        case "cancel-close-popup-template-response":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state = createInitModal(state);
            break;
        case "close-popup-template-response":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.inputs = tslib_1.__assign({}, state.inputs);
            state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
            if (state.toggles.videoTagModal) {
                state.inputs.templateResponseVideoTagData = state.templateResponsesPage.viewVideoTagData;
            }
            else if (state.toggles.youtubeModal) {
                state.inputs.templateResponseYoutubeId = state.templateResponsesPage.youtubeId;
            }
            state = createInitModal(state);
            break;
        case "open-popup-template-response":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
            state = createInitModal(state);
            var tr = bisect_1.Indexer.getFirstMatching(state.stores.templateResponses.byId, [action.id]);
            if (tr) {
                state.toggles.showTemplateResponseCreateModal = true;
                state.toggles.showViewModeVideoTagModal = true;
                var templateResponseVideoTagDataList = [];
                if (tr.attributes.settings && tr.attributes.settings !== null && tr.attributes.settings.length > 0) {
                    tr.attributes.settings.map(function (data) {
                        if (data.kind && data.kind === "video_tag") {
                            var templateResponseVideoTagData = data;
                            templateResponseVideoTagData.url = data.src_url;
                            templateResponseVideoTagDataList.push(templateResponseVideoTagData);
                            state.toggles.videoTagModal = true;
                        }
                    });
                }
                state.templateResponsesPage.viewVideoTagData = templateResponseVideoTagDataList;
            }
            break;
        case "open-popup-youtube-template-response":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
            state = createInitModal(state);
            var tr = bisect_1.Indexer.getFirstMatching(state.stores.templateResponses.byId, [action.id]);
            if (tr) {
                state.toggles.showTemplateResponseCreateModal = true;
                state.toggles.showViewModeVideoTagModal = true;
                if (tr.attributes.settings && tr.attributes.settings !== null && tr.attributes.settings.length > 0) {
                    tr.attributes.settings.map(function (data) {
                        if (data.kind && data.kind === "youtube" && data.youtube_id && data.youtube_id !== null && data.youtube_id !== "") {
                            state.templateResponsesPage.youtubeId = data.youtube_id;
                            state.toggles.youtubeModal = true;
                        }
                    });
                }
            }
            break;
        case "open-popup-create-template-response":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
            state = createInitModal(state);
            state.toggles.showTemplateResponseCreateModal = true;
            state.toggles.videoTagModal = true;
            state.toggles.showViewModeVideoTagModal = false;
            state.templateResponsesPage.viewVideoTagData = state.inputs.templateResponseVideoTagData;
            break;
        case "open-popup-youtube-create-template-response":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
            state = createInitModal(state);
            state.toggles.showTemplateResponseCreateModal = true;
            state.toggles.youtubeModal = true;
            state.toggles.showViewModeVideoTagModal = false;
            state.templateResponsesPage.youtubeId = state.inputs.templateResponseYoutubeId;
            break;
        case "upload-template-response-video-tag-file":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
            if (state.toggles.showViewModeVideoTagModal) {
                break;
            }
            state.toggles.loadingFlg = true;
            state.templateResponsesPage.uploadFileErrorFlg = false;
            state.templateResponsesPage.viewVideoTagData = [];
            var answerFiles = action.event.target.files;
            effects = sequence_services_1.sequence(effects, upload_normal_services_1.requestNormalUpload([exports.uploadTemplateResponseFileRequestName], answerFiles, upload_normal_services_1.uploadNormalFileKindVideoTag));
            break;
        case "upload-template-response-video-tag-thumbnail-file":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
            if (state.toggles.showViewModeVideoTagModal) {
                break;
            }
            state.templateResponsesPage.uploadFileErrorFlg = false;
            state.inputs.templateResponseThumbnailLoadingIdx.push(action.idx);
            var answerThumbnailFiles = action.event.target.files;
            effects = sequence_services_1.sequence(effects, upload_normal_services_1.requestNormalUpload([exports.uploadTemplateResponseThumbnailFileRequestName, String(action.idx)], answerThumbnailFiles, upload_normal_services_1.uploadNormalFileKindVideoTagThumbnail));
            break;
        case "delete-video-tag-template-response":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
            if (state.toggles.showViewModeVideoTagModal) {
                break;
            }
            if (state.toggles.showVideoTagDeleteModal) {
                state.toggles.showVideoTagDeleteModal = false;
                state.templateResponsesPage.viewVideoTagData = [];
                (_m = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.templateResponseModalDeletedVideoMessage[language])), state = _m.state, effects = _m.effects);
            }
            else {
                state.toggles.showVideoTagDeleteModal = true;
            }
            break;
        case "delete-template-response-video-tag-thumbnail-file":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
            if (state.toggles.showViewModeVideoTagModal) {
                break;
            }
            if (state.toggles.showVideoTagThumbnailDeleteModal) {
                state.toggles.showVideoTagThumbnailDeleteModal = false;
                if (state.inputs.templateResponseDeleteVideoTagId && state.inputs.templateResponseDeleteVideoTagId !== null && state.inputs.templateResponseDeleteVideoTagId !== "") {
                    state.inputs.templateResponseThumbnailLoadingIdx.push(state.inputs.templateResponseDeleteVideoTagIdx);
                    effects = sequence_services_1.sequence(effects, getDefaultVideoTagThumbnailTemplateResponseQueryParams(state, state.inputs.templateResponseId, state.inputs.templateResponseDeleteVideoTagId, state.inputs.templateResponseDeleteVideoTagIdx));
                }
                else {
                    state.templateResponsesPage.viewVideoTagData[state.inputs.templateResponseDeleteVideoTagIdx].thumbnail_url = null;
                }
                state.inputs.templateResponseDeleteVideoTagIdx = null;
                state.inputs.templateResponseDeleteVideoTagId = null;
            }
            else {
                state.toggles.showVideoTagThumbnailDeleteModal = true;
                state.inputs.templateResponseDeleteVideoTagId = action.video_tag_id;
                state.inputs.templateResponseDeleteVideoTagIdx = action.idx;
            }
            break;
        case "delete-youtube-template-response":
            state = tslib_1.__assign({}, state);
            state.toggles = tslib_1.__assign({}, state.toggles);
            state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
            if (state.toggles.showViewModeVideoTagModal) {
                break;
            }
            if (state.toggles.showYoutubeDeleteModal) {
                state.toggles.showYoutubeDeleteModal = false;
                state.templateResponsesPage.youtubeId = "";
                (_o = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.templateResponseModalDeletedYoutubeMessage[language])), state = _o.state, effects = _o.effects);
            }
            else {
                state.toggles.showYoutubeDeleteModal = true;
            }
            break;
        case "change-youtube-id-template-response":
            state = tslib_1.__assign({}, state);
            state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage);
            state.templateResponsesPage.youtubeId = "";
            if (action.value && action.value !== null && /^[A-Za-z0-9_-]{11}$/.test(action.value)) {
                state.templateResponsesPage.youtubeId = action.value;
                (_p = sequence_services_1.sequenceReduction(effects, toast_reducer_1.openToast(state, I18n_1.i18n.uploadSuccessMessage[language])), state = _p.state, effects = _p.effects);
            }
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceTemplateResponses = reduceTemplateResponses;
function createInitModal(state) {
    state.toggles = tslib_1.__assign({}, state.toggles, { showTemplateResponseCreateModal: false, showViewModeVideoTagModal: false, videoTagModal: false, youtubeModal: false, showVideoTagDeleteModal: false, showVideoTagThumbnailDeleteModal: false, showYoutubeDeleteModal: false, loadingFlg: false });
    state.templateResponsesPage = tslib_1.__assign({}, state.templateResponsesPage, { uploadFileErrorFlg: false, viewVideoTagData: [], youtubeId: "" });
    state.inputs.templateResponseThumbnailLoadingIdx = [];
    return state;
}
exports.createInitModal = createInitModal;
function getTemplateResponseQueryParams(state) {
    var query = { filter: {} };
    var searchString = (state.inputs.templateResponseSearch || "").trim();
    if (searchString) {
        query.filter["template_response_like"] = searchString;
    }
    return query;
}
exports.getTemplateResponseQueryParams = getTemplateResponseQueryParams;
function getDefaultVideoTagThumbnailTemplateResponseQueryParams(state, templateResponseId, videoTagId, idx) {
    var query = {};
    query["template_response_id"] = templateResponseId;
    query["video_tag_id"] = videoTagId;
    return json_resources_1.requestResourceFetch([exports.deleteVideoTagThumbnailFileTemplateResponseRequestName, String(idx)], routes_1.RapiV1ShowContentsPath + "/video_tag_default_thumbnail", query);
}
exports.getDefaultVideoTagThumbnailTemplateResponseQueryParams = getDefaultVideoTagThumbnailTemplateResponseQueryParams;
function loadTemplateResponses(searchString, sort, desc) {
    if (searchString === void 0) { searchString = ""; }
    if (sort === void 0) { sort = "title"; }
    if (desc === void 0) { desc = false; }
    var query = {};
    if (searchString) {
        query.filter = { template_response_like: searchString };
    }
    var sortParam = (desc ? "-" : "") + sort;
    query.sort = [sortParam];
    return json_resources_1.requestResourceFetch([exports.templateResponseLoadRequestName], routes_1.RapiV1TemplateResponsesPath, query);
}
exports.loadTemplateResponses = loadTemplateResponses;
exports.templateResponseLoadRequestName = "template-responses-data";
exports.saveTemplateResponseRequestName = "save-template-response-request";
exports.deleteTemplateResponseRequestName = "delete-template-response-request";
exports.uploadTemplateResponseFileRequestName = "upload-template-response-file-request";
exports.uploadTemplateResponseThumbnailFileRequestName = "upload-template-response-thumbnail-file-request";
exports.createVideoTagUrlsRequestName = "create-video-tag-urls-request";
exports.deleteVideoTagThumbnailFileTemplateResponseRequestName = "delete-template-response-thumbnail-file-request";
exports.templateResponsesPagedRequestNames = [exports.templateResponseLoadRequestName];
