"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var user_bubble_1 = require("./user-bubble");
var image_message_content_1 = require("./image-message-content");
var color_editable_1 = require("./color-editable");
var time_format_1 = require("./time-format");
var video_message_content_1 = require("./video-message-content");
var audio_message_content_1 = require("./audio-message-content");
var link_message_content_1 = require("./link-message-content");
var widget_file_message_content_1 = require("./widget-file-message-content");
var string_utils_1 = require("../utils/string-utils");
var video_tag_message_content_1 = require("./video-tag-message-content");
var youtube_message_content_1 = require("./youtube-message-content");
function WidgetMessage(props) {
    var isMessageDataValid = props.message && (props.message.attributes.kind !== "text" && props.message.attributes.settings) ||
        (props.message.attributes.kind === "text" && props.message.attributes.content);
    var isCustomer = !!(props.user && props.user.attributes.user_type && props.user.attributes.user_type === "Customer");
    var isImage = !!(props.message && props.message.attributes.kind && props.message.attributes.kind === "image");
    var textColorKey = "operator_message_text";
    var backgroundColor = "operator_message_background";
    if (isCustomer) {
        textColorKey = "customer_message_text";
        backgroundColor = "customer_message_background";
    }
    var textColor = props.colors[textColorKey];
    return !isMessageDataValid ? null : React.createElement("div", { className: "w-100 dib pv1 pr2" },
        !isCustomer && React.createElement("div", { className: "dib fl" },
            React.createElement(user_bubble_1.UserBubble, { small: true, user: props.user, tenant: props.tenant })),
        React.createElement(color_editable_1.ColorEditable, { className: (isCustomer ? "fr mw-85 bbrr0" : "fl mw-80 bblr0") + (isImage ? " w13_75rem" : "") + " pa3 mv1 f4 fw4 br4 b--near-white word-break", colors: props.colors, editedColors: {
                backgroundColor: backgroundColor,
                color: textColorKey,
            } }, (function () {
            switch (props.message.attributes.kind) {
                case "text":
                    return React.createElement("div", { className: "dib relative tracked-light-s " + props.id }, string_utils_1.formatLineBreak(props.message.attributes.content && props.message.attributes.content.trim(), props.message.id, textColor));
                case "image":
                    return React.createElement(image_message_content_1.ImageMessageContent, { message: props.message, isSmall: true });
                case "file":
                    return React.createElement(widget_file_message_content_1.WidgetFileMessageContent, { message: props.message });
                case "video":
                    return React.createElement(video_message_content_1.VideoMessageContent, { message: props.message, isSmall: true });
                case "audio":
                    return React.createElement(audio_message_content_1.AudioMessageContent, { message: props.message, isSmall: true });
                case "link":
                    return React.createElement(link_message_content_1.LinkMessageContent, { settings: props.message.attributes.settings, color: textColor });
                case "video_tag":
                    return React.createElement(video_tag_message_content_1.VideoTagMessageContent, { message: props.message, language: props.language });
                case "youtube":
                    return React.createElement(youtube_message_content_1.YoutubeMessageContent, { message: props.message, language: props.language });
            }
        })()),
        React.createElement(color_editable_1.ColorEditable, { className: (isCustomer ? "fr mr1" : "fl ml-2_5 pl1") + " cb f6 tracked-light", colors: props.colors, editedColors: {
                color: "message_date_text",
            } },
            React.createElement(time_format_1.TimeFormat, { unixTime: props.message.attributes.created_at_unix, timeFormat: props.timeFormat })));
}
exports.WidgetMessage = WidgetMessage;
