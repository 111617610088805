"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var chat_room_reducer_1 = require("./chat-room-reducer");
var complete_request_reducer_1 = require("./complete-request-reducer");
var initial_loading_reducer_1 = require("./initial-loading-reducer");
var json_resources_1 = require("../../core/json-resources");
var routes_1 = require("../../routes");
var popup_messages_reducer_1 = require("../../reducers/popup-messages-reducer");
var sequence_services_1 = require("../../core/services/sequence-services");
var last_read_at_reducer_1 = require("../../reducers/last-read-at-reducer");
var bisect_1 = require("../../utils/bisect");
function loadRoom(roomId) {
    return {
        type: "load-room",
        roomId: roomId,
    };
}
exports.loadRoom = loadRoom;
function createNewRoom() {
    return {
        type: "create-new-room",
    };
}
exports.createNewRoom = createNewRoom;
function loadOldRooms() {
    return {
        type: "load-old-rooms",
    };
}
exports.loadOldRooms = loadOldRooms;
function goToRoom(state, roomId) {
    var _a;
    var effects = [];
    state = tslib_1.__assign({}, state);
    state.previousWidgetPages = state.previousWidgetPages.concat(state.currentWidgetPage);
    state.currentWidgetPage = "room";
    state.currentViewingRoomId = roomId;
    effects = sequence_services_1.sequence(effects, chat_room_reducer_1.requestChatHistory(roomId));
    effects = sequence_services_1.sequence(effects, exports.requestFormInputs(roomId));
    effects = sequence_services_1.sequence(effects, popup_messages_reducer_1.requestRoomMembers(roomId));
    if (roomId) {
        var room = bisect_1.Indexer.getFirstMatching(state.stores.rooms.byId, [roomId]);
        if (room) {
            state.currentViewingRoomName = room.attributes.name;
        }
    }
    (_a = sequence_services_1.sequenceReduction(effects, last_read_at_reducer_1.tryToMarkReadWidget(state)), state = _a.state, effects = _a.effects);
    return { state: state, effects: effects };
}
exports.goToRoom = goToRoom;
function reduceRoomList(state, action) {
    var _a;
    var effects = [];
    switch (action.type) {
        case "load-room":
            if (state.plan_settings.scenario_chat_flg && action.roomId && state.currentViewingRoomId !== action.roomId) {
                state.scenarioTemplatesData = [];
                var newData = chat_room_reducer_1.getScenarioTemplateData(state, action.roomId);
                state = tslib_1.__assign({}, state, newData.state);
                effects = sequence_services_1.sequence(effects, newData.effects);
            }
            (_a = sequence_services_1.sequenceReduction(effects, goToRoom(state, action.roomId)), state = _a.state, effects = _a.effects);
            break;
        case "create-new-room":
            state = tslib_1.__assign({}, state);
            state.previousWidgetPages = state.previousWidgetPages.concat(state.currentWidgetPage);
            state.currentWidgetPage = "room";
            state.currentViewingRoomId = "";
            if (state.plan_settings.scenario_chat_flg) {
                state.scenarioTemplatesData = [];
                var newData = chat_room_reducer_1.getScenarioTemplateData(state, null);
                state = tslib_1.__assign({}, state, newData.state);
                effects = sequence_services_1.sequence(effects, newData.effects);
            }
            break;
        case "load-old-rooms":
            effects = sequence_services_1.sequence(effects, complete_request_reducer_1.loadNextPageIfNeeded(state, initial_loading_reducer_1.loadCustomerRoomsRequestName));
            break;
        case "complete-request":
            if (action.name[0] === last_read_at_reducer_1.markReadRequestName) {
                var roomId = action.name[1];
                state.isRoomMarkingRead = tslib_1.__assign({}, state.isRoomMarkingRead);
                state.isRoomMarkingRead[roomId] = false;
            }
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceRoomList = reduceRoomList;
exports.requestFormInputs = function (roomId) {
    var query = {};
    query.include = ["survey"];
    return json_resources_1.requestResourceFetch([exports.getQuestionsRequestName, roomId], routes_1.RapiV1RoomsPath + "/" + roomId + "/questions", query);
};
exports.getQuestionsRequestName = "get-questions-request-name";
