"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var class_generator_1 = require("./class-generator");
var classNamesForSelectSingle = class_generator_1.classNamesGeneratorFor(function (add) {
    add("value", React.createElement("div", { className: "" }), React.createElement("div", { className: "gray" }));
}, React.createElement("div", { className: "w-100 b--solid b--moon-gray bw1 br3 b--light-blue--hover input-reset bg-white h2-5 ph3" }));
var defaultLabeler = function (o) { return o + ""; };
function SelectSingle(props) {
    var labeler = props.labeler || defaultLabeler;
    var onChange = props.onChange ?
        function (event) {
            var selected = event.target.value;
            var valueNum = selected ? parseInt(selected) : null;
            return props.onChange(props.values[valueNum]);
        } : null;
    var valueIdx = -1;
    if (props.value) {
        valueIdx = props.values.indexOf(props.value);
    }
    return React.createElement("div", { className: "gpu w-100 dib" },
        React.createElement("div", { className: "absolute right-1 mt select-arrows" },
            React.createElement("div", { className: "arrow-up mb0_3" }),
            React.createElement("div", { className: "arrow-down" })),
        React.createElement("select", { onKeyDown: props.onKeyDown, className: classNamesForSelectSingle(props), value: valueIdx, style: props.style, onChange: onChange },
            props.hidePlaceholder ? null : React.createElement("option", { key: "-1", value: "-1", label: props.placeholder }, props.placeholder),
            props.values.map(function (value, idx) {
                return React.createElement("option", { key: idx.toString(), value: idx.toString(), label: labeler(value) }, labeler(value));
            })));
}
exports.SelectSingle = SelectSingle;
