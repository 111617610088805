"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
function goBack() {
    return {
        type: "go-back",
    };
}
exports.goBack = goBack;
function reduceHeader(state, action) {
    var effects = [];
    switch (action.type) {
        case "go-back":
            state = tslib_1.__assign({}, state);
            var previousWidgetPages = state.previousWidgetPages.slice();
            var previousPage = (state.previousWidgetPages[0] && state.previousWidgetPages[0] === "room" && state.currentWidgetPage === "room") ? "roomList" : previousWidgetPages.pop();
            state.currentWidgetPage = previousPage;
            state.currentViewingRoomId = "";
            state.popupMessageIds = [];
            break;
    }
    return { state: state, effects: effects };
}
exports.reduceHeader = reduceHeader;
