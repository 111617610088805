"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tslib_1 = require("tslib");
var React = require("react");
var widget_state_1 = require("../reducers/widget-state");
var bisect_1 = require("../../utils/bisect");
var resource_api_1 = require("../../schemas/resource-api");
var layouts_1 = require("../../components/layouts");
var svgs_1 = require("../../components/svgs");
var user_bubble_1 = require("../../components/user-bubble");
var toggles_reducer_1 = require("../../reducers/subreducers/toggles-reducer");
var color_editable_1 = require("../../components/color-editable");
var unread_message_pill_1 = require("../../components/unread-message-pill");
var key_navigation_utils_1 = require("../../utils/key-navigation-utils");
var settings_page_reducer_1 = require("../reducers/settings-page-reducer");
var header_reducer_1 = require("../reducers/header-reducer");
var memoizers_1 = require("../../utils/memoizers");
var object_helpers_1 = require("../../utils/object-helpers");
var chat_room_reducer_1 = require("../reducers/chat-room-reducer");
var events_1 = require("../events");
function UserBubbles(props) {
    return React.createElement(layouts_1.Row, { stretchRow: true, className: "justify-center" }, props.users && props.users.map(function (user, i) {
        if (i >= props.numUsersToShow)
            return;
        return React.createElement("div", { className: "" + (props.spread ? "mh3" : ""), key: "online-operator-" + user.id },
            React.createElement(user_bubble_1.UserBubble, { className: "" + (!props.spread ? "nl3" : ""), user: user, showStatus: true, medium: true, over: !props.spread, narrow: !props.spread, wide: props.spread, tenant: props.tenant, withoutUniqueName: true }));
    }));
}
function BackButton(props) {
    return React.createElement(layouts_1.Column, { fixedColumn: true, className: props.classNameForBackButton },
        React.createElement("div", { tabIndex: 0, role: "button", className: "ma pointer glow o-70 test--room-list-button", onClick: props.onClick, style: { outlineColor: props.colors.outline }, onKeyDown: props.onKeyDown },
            React.createElement("div", { tabIndex: -1, className: "no-focus-glow" },
                React.createElement(svgs_1.SVGBackArrow, { width: 17, height: 17 }))),
        React.createElement(unread_message_pill_1.UnreadMessagePill, { colors: props.colors, className: props.classNameForUnreadMsgPill + " dib absolute z-5", relative: true, unreadCount: props.unreadCount }));
}
function CloseButton(props) {
    if (props.providerLoginMode)
        return null;
    return React.createElement(layouts_1.Column, { fixedColumn: true },
        React.createElement("div", { tabIndex: 0, role: "button", className: props.className, style: { outlineColor: props.colors.outline }, onClick: props.onClick, onKeyDown: props.onKeyDown },
            React.createElement("div", { tabIndex: -1, className: "no-focus-glow" },
                React.createElement(svgs_1.SVGCloseNoCircle, { width: 33, height: 33 }))));
}
function LittleHeader(props) {
    return React.createElement(layouts_1.Row, { stretchRow: true, className: "h4" },
        props.showBackButton &&
            React.createElement(BackButton, { classNameForBackButton: "ph3", classNameForUnreadMsgPill: "left-2 ml2 mt-6", unreadCount: props.unreadCount, colors: props.colors, onClick: props.backButtonClick, onKeyDown: props.backButtonKeyDown }),
        React.createElement(layouts_1.Column, { className: "w-100", stretchColumn: true },
            React.createElement("div", { className: "mt3 pt2 ml3 pl2", style: props.aiflg ? { display: "none" } : {}, id: "oksky-chat-user-buddle-header" },
                props.operator ?
                    React.createElement(layouts_1.Row, { fixedRow: true, className: "fl ph2" },
                        React.createElement(layouts_1.Column, { stretchColumn: true, className: "nl3" },
                            React.createElement(user_bubble_1.UserBubble, { user: props.operator, showStatus: true, tenant: props.tenant, over: true, medium: true, withoutUniqueName: true })))
                    :
                        React.createElement("div", { className: "fl ph2" },
                            React.createElement(UserBubbles, { users: props.canSupportOperators, numUsersToShow: 3, spread: false, tenant: props.tenant })),
                React.createElement("div", { className: "pt3 mt2 overflow-hidden f3 fw1 word-wrap tracked-light-xl test--header-title" }, props.title))),
        React.createElement(CloseButton, { onClick: function () { events_1.chatCloseTracker(props.roomId, props.client_id, props.clientCode); props.onClose(); }, providerLoginMode: props.providerLoginMode, onKeyDown: props.closeOnKeyDown, colors: props.colors, className: "pr2 ma pointer glow o-70" }));
}
function BigHeader(props) {
    return React.createElement(layouts_1.Row, { stretchRow: true, className: "h10_5 tc" },
        props.showBackButton ? React.createElement(BackButton, { classNameForBackButton: "absolute pt3 pl2 left-1", classNameForUnreadMsgPill: "nt3 ml-70", unreadCount: props.unreadCount, colors: props.colors, onClick: props.backButtonClick, onKeyDown: props.backButtonKeyDown }) :
            React.createElement(color_editable_1.ColorEditable, { className: "absolute pt3 pl2 left-0 pointer glow o-70", tabIndex: 0, onClick: props.goToSettingsPage, onKeyDown: function (e) { return key_navigation_utils_1.isEnterOrSpace(e) && props.goToSettingsPage(); }, colors: props.colors, editedColors: {
                    fill: "navigation_bar_button_fill",
                    outlineColor: "outline",
                } },
                React.createElement(svgs_1.SVGKebab, { className: "w3 h3" })),
        React.createElement(layouts_1.Column, { stretchColumn: true, className: "w-100 ph5" },
            React.createElement("div", { className: "ma" },
                React.createElement("div", { className: "w-100" },
                    React.createElement("div", { className: "mt4 overflow-hidden f1 word-wrap tracked-light-xl" }, props.title),
                    React.createElement("div", { className: "h6_5 pt3 f3 overflow-hidden word-wrap lh-title tracked-light-m" }, props.subtitle)),
                React.createElement("div", { style: props.aiflg ? { display: "none" } : {} },
                    React.createElement(UserBubbles, { users: props.canSupportOperators, numUsersToShow: 3, spread: true, tenant: props.tenant })))),
        React.createElement(CloseButton, { onClick: function () { events_1.chatCloseTracker(props.roomId, props.client_id, props.clientCode); props.onClose(); }, providerLoginMode: props.providerLoginMode, onKeyDown: props.closeOnKeyDown, colors: props.colors, className: "absolute pt3 right-1 pointer glow o-70" }));
}
var Header;
(function (Header) {
    var getRelationshipId = resource_api_1.ResourceApi.getRelationshipId;
    function render(dispatch) {
        var dispatcher = {
            goBackClick: function () { return dispatch(header_reducer_1.goBack()); },
            goBackKeyDown: function (e) {
                if (key_navigation_utils_1.isEnterOrSpace(e)) {
                    dispatch(header_reducer_1.goBack());
                }
            },
            toggleWidgetMaximized: function (e, roomId, client_id, clientCode) {
                if (key_navigation_utils_1.isEnterOrSpace(e)) {
                    events_1.chatCloseTracker(roomId, client_id, clientCode);
                    dispatch(toggles_reducer_1.toggle("widgetMaximized"));
                }
            },
            goToSettingsPage: function () { return dispatch(settings_page_reducer_1.goToSettingsPage()); },
            downloadMessageSearchCSV: function (roomId) { return dispatch(chat_room_reducer_1.downloadMessageSearchCSV(roomId)); },
            setHideHeader: function (headerToggle) { return dispatch(chat_room_reducer_1.setHideHeader(headerToggle)); },
        };
        return memoizers_1.memoizeBySomeProperties({
            stores: object_helpers_1.pick(widget_state_1.WidgetV2State.initialState.stores, "users", "rooms", "members", "supports"),
            loggedInUser: widget_state_1.WidgetV2State.initialState.loggedInUser,
            currentViewingRoomId: widget_state_1.WidgetV2State.initialState.currentViewingRoomId,
            currentWidgetPage: widget_state_1.WidgetV2State.initialState.currentWidgetPage,
            header_title: widget_state_1.WidgetV2State.initialState.header_title,
            reply_guidance: widget_state_1.WidgetV2State.initialState.reply_guidance,
            app_settings: widget_state_1.WidgetV2State.initialState.app_settings,
            header_description: widget_state_1.WidgetV2State.initialState.header_description,
            colors: widget_state_1.WidgetV2State.initialState.colors,
            provider_login_mode: widget_state_1.WidgetV2State.initialState.provider_login_mode,
            is_aws_present: widget_state_1.WidgetV2State.initialState.is_aws_present,
            hideHeader: widget_state_1.WidgetV2State.initialState.hideHeader,
            isAndroid: widget_state_1.WidgetV2State.initialState.isAndroid,
            thisTenant: widget_state_1.WidgetV2State.initialState.thisTenant,
            client_id: widget_state_1.WidgetV2State.initialState.client_id,
            clientCode: widget_state_1.WidgetV2State.initialState.clientCode,
        }, function (state) {
            var rooms = bisect_1.Indexer.getAllMatching(state.stores.rooms.byCreateUserId, [state.loggedInUser.id]);
            var room = bisect_1.Indexer.getFirstMatching(state.stores.rooms.byId, [state.currentViewingRoomId]);
            var support = room && bisect_1.Indexer.getFirstMatching(state.stores.supports.byRoomIdAndIsEnded, [room.id, false]);
            var operator = support && bisect_1.Indexer.getFirstMatching(state.stores.users.byId, [getRelationshipId(support, "user")]);
            var canSupportOperators = bisect_1.Indexer.getAllMatching(state.stores.users.byNotCustomerAndCanSupport, [true, true]);
            var inRoom = state.currentWidgetPage === "room";
            var inRoomList = state.currentWidgetPage === "roomList";
            var inSettingsPage = state.currentWidgetPage === "settings";
            var title = (operator && operator.attributes.username) || state.header_title || (state.app_settings && state.app_settings.name || "").toLocaleUpperCase();
            var subTitle = (operator && operator.attributes.status) || (inRoom ? state.reply_guidance : state.header_description) || "";
            var showBigHeader = inRoomList || !state.currentViewingRoomId;
            var roomsCreated = bisect_1.Indexer.getAllMatching(state.stores.rooms.byId, []).length >= 1;
            var showBackButton = (inRoom && roomsCreated) || inSettingsPage;
            var totalUnreadMessageCount = 0;
            rooms.filter(function (room) {
                return room.id !== state.currentViewingRoomId;
            }).map(function (room) {
                var member = bisect_1.Indexer.getFirstMatching(state.stores.members.byRoomIdAndUserId, [room.id, state.loggedInUser.id]);
                totalUnreadMessageCount += member && member.attributes.unread_message_count || 0;
            });
            var headerProps = {
                title: title,
                subtitle: subTitle,
                showBackButton: showBackButton,
                backButtonClick: dispatcher.goBackClick,
                backButtonKeyDown: dispatcher.goBackKeyDown,
                canSupportOperators: canSupportOperators,
                onClose: toggles_reducer_1.toggleDispatcher(dispatch, "widgetMaximized"),
                closeOnKeyDown: function (e) { return (dispatcher.toggleWidgetMaximized(e, state.currentViewingRoomId, state.client_id, state.clientCode)); },
                unreadCount: totalUnreadMessageCount,
                colors: state.colors,
                providerLoginMode: state.provider_login_mode,
                language: state.loggedInUser.attributes.language,
                tenant: state.thisTenant,
                roomId: state.currentViewingRoomId,
                client_id: state.client_id,
                clientCode: state.clientCode,
            };
            var room_settings = room && room.attributes.settings;
            var bigHeaderAiFlg = false;
            if (room == null || room_settings == null) {
                bigHeaderAiFlg = true;
            }
            else {
                bigHeaderAiFlg = (room_settings && room_settings.ai_chat) || false;
            }
            var bigHeaderProps = tslib_1.__assign({}, headerProps, { goToSettingsPage: dispatcher.goToSettingsPage, aiflg: bigHeaderAiFlg });
            var littleHeaderAiflg = (room_settings && room_settings.ai_chat) || false;
            var littleHeaderProps = tslib_1.__assign({}, headerProps, { operator: operator, aiflg: littleHeaderAiflg });
            if (state.isAndroid) {
                var initialInnerHeight_1 = window.innerHeight;
                window.addEventListener('resize', function () {
                    if (initialInnerHeight_1 > window.innerHeight) {
                        dispatcher.setHideHeader(true);
                    }
                    else {
                        dispatcher.setHideHeader(false);
                    }
                });
            }
            return React.createElement(color_editable_1.ColorEditable, { className: "w-100 br0-s test--header " + (state.hideHeader ? " none" : ""), colors: state.colors, editedColors: {
                    backgroundColor: "navigation_bar_background",
                    color: "navigation_bar_text",
                    fill: "navigation_bar_button_fill"
                } }, showBigHeader ? React.createElement(BigHeader, tslib_1.__assign({}, bigHeaderProps)) :
                React.createElement(LittleHeader, tslib_1.__assign({}, littleHeaderProps)));
        });
    }
    Header.render = render;
})(Header = exports.Header || (exports.Header = {}));
